export type RoleNames =
  | 'SuperVPlaybookAdmin'
  | 'PlaybookOwner'
  | 'VPlaybookReader'
  | 'User'
  | 'CompanyReader'
  | 'SpecificVPlaybookAdmin'
  | 'StandardVPlaybookAdmin'
  | 'SalesManager'
  | 'Administrator';

export enum UserRoleEnum {
  SuperVPlaybookAdmin = 1,
  StandardVPlaybookAdmin = 2,
  Administrator = 3,
  PlaybookOwner = 4,
  User = 5,
  VPlaybookReader = 6,
  CompanyReader = 7,
  SpecificVPlaybookAdmin = 8,
  SalesManager = 9,
}

export enum UserRole {
  SuperVPlaybookAdmin = 'SuperVPlaybookAdmin',
  StandardVPlaybookAdmin = 'StandardVPlaybookAdmin',
  Administrator = 'Administrator',
  PlaybookOwner = 'PlaybookOwner',
  User = 'User',
  VPlaybookReader = 'VPlaybookReader',
  CompanyReader = 'CompanyReader',
  SpecificVPlaybookAdmin = 'SpecificVPlaybookAdmin',
  SalesManager = 'SalesManager',
}

export const UserRolePairs = {
  SuperVPlaybookAdmin: 1,
  StandardVPlaybookAdmin: 2,
  Administrator: 3,
  PlaybookOwner: 4,
  User: 5,
  VPlaybookReader: 6,
  CompanyReader: 7,
  SpecificVPlaybookAdmin: 8,
  SalesManager: 9,
};
